import cn from 'classnames';
import { useRef } from 'react';
import FocusLock from 'react-focus-lock';

import { useKeyDownListener } from '../../../../hooks/useKeyDownListener';
import { useModalVisibility } from '../../useModalVisibility';
import styles from './ModalVideo.module.scss';
interface IProps {
  src: string;
}

export const ModalVideo: React.FC<IProps> = ({ src }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { isVisible, onClose } = useModalVisibility();

  useKeyDownListener(onClose);

  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (modalRef?.current && !modalRef?.current.contains(target)) {
      onClose();
    }
  };

  return (
    <FocusLock autoFocus={false}>
      <div
        className={cn(styles.modalVideo, { [styles.modalVideoOverlayVisible]: isVisible })}
        tabIndex={-1}
        role="dialog"
        aria-label="You just openned the modal video"
        onClick={e => handleClickOutside(e)}
      >
        <div className={cn(styles.modalVideoBody, { [styles.modalVideoBodyVisible]: isVisible })}>
          <div className={styles.modalVideoInner} id="modal-video-inner-LGUXA0WB6F9J7">
            <div className={styles.modalVideoMovieWrap} ref={modalRef}>
              <button
                onClick={onClose}
                className={styles.modalVideoCloseBtn}
                aria-label="Close the modal by clicking here"
              ></button>
              <iframe
                width="460"
                height="230"
                src={src}
                frameBorder="0"
                allowFullScreen={true}
                tabIndex={-1}
                allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                title="video player"
              />
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  );
};
