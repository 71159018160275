import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSetSearchParamsNotSavedInHistory } from './useSetSearchParamsNotSavedInHistory';

export const usePagination = () => {
  const [searchParams] = useSearchParams();
  const { setSearchParamsNotSavedInHistory } = useSetSearchParamsNotSavedInHistory();
  const params: { [key: string]: any } = {};

  searchParams.forEach((value, key) => {
    if (key in params) {
      params[key] = [...params[key], value];
    } else {
      params[key] = [value];
    }
  });

  const urlPage = Number(searchParams.get('page'));
  const validUrlPage = urlPage === 0 ? 1 : urlPage;
  const [page, setPage] = useState<number>(validUrlPage);

  const onPageChange = ({ selected }) => {
    const currPage = selected + 1;

    setSearchParamsNotSavedInHistory({ ...params, page: String(currPage) });
    setPage(currPage);
  };

  return { page, onPageChange };
};
