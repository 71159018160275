import cn from 'classnames';
import { useState } from 'react';

import styles from './SearchPopup.module.scss';

interface ISelects {
  onSelect: (selected: string) => void;
  list: string[];
  classNameItem?: string;
}

export const Selects: React.FC<ISelects> = ({ onSelect, list, classNameItem }) => {
  const [selected, setSelected] = useState('');

  const clsn = (currSelected: string) =>
    cn(classNameItem, { [styles.active]: selected === currSelected });

  const onWillSelect = (currSelected: string) => () => {
    setSelected(currSelected);
    onSelect(currSelected);
  };

  return (
    <div className={styles.list}>
      {list.map((item, idx) => {
        const formattedItem = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();

        return (
          <div key={idx} className={clsn(item)} onClick={onWillSelect(item)}>
            {formattedItem}
          </div>
        );
      })}
    </div>
  );
};
