import cn from 'classnames';
import React from 'react';
import FocusLock from 'react-focus-lock';

import closeIcon from '../../assets/svg/close-icon.svg';
import styles from './SidePanel.module.scss';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  isVisible: boolean;
  className?: string;
};

export const SidePanel: React.FC<Props> = ({ isVisible, onClose, children, className }) => {
  const handleClosePanel = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <FocusLock autoFocus={false}>
      <div
        className={cn(styles.sidePanel, className, { [styles.sidePanelVisible]: isVisible })}
        onClick={handleClosePanel}
      >
        <div
          className={cn(styles.sidePanelContent, {
            [styles.sidePanelContentVisible]: isVisible,
          })}
        >
          <button className={styles.sidePanelCloseButton} onClick={onClose}>
            <img src={closeIcon} alt="close icon" />
          </button>
          <div className={styles.sidePanelContainer}>{children}</div>
        </div>
      </div>
    </FocusLock>
  );
};
