import cn from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { EyeIcon } from '../../../../../assets/svg/EyeIcon';
import { VideoPlayer } from '../../../../../components/VideoPlayer';
import { ITopWebinar } from '../../../../../models/IHome';
import { PAGES_ENUM } from '../../../../../utils/constants';
import styles from './OurWebinarCard.module.scss';

type Props = {
  webinar: ITopWebinar;
};

export const OurWebinarCard: React.FC<Props> = ({ webinar }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { pk, author, lookups, main_video, preview_text, title, main_image } = webinar;

  return (
    <div className={styles.webinarCard}>
      <div
        className={cn(styles.webinarCardVideoContainer, {
          [styles.webinarCardAspectRatioContainer]: isVideoPlaying,
        })}
      >
        <VideoPlayer
          onStart={() => setIsVideoPlaying(true)}
          url={main_video}
          thumbnail={main_image}
          className={styles.webinarCardVideo}
        />
      </div>
      <div className={styles.webinarCardContent}>
        <div className={styles.webinarCardInfo}>
          <Link to={`${PAGES_ENUM.ONLINE_LESSONS}/` + pk}>
            <h3 className={styles.webinarCardTitle}>{title}</h3>
          </Link>
          <p className={styles.webinarCardDescription}>{preview_text}</p>
        </div>
        <div className={styles.webinarCardFooter}>
          <div className={styles.webinarCardViews}>
            <EyeIcon className={styles.webinarCardViewIcon} />
            {lookups}
          </div>
          <div className={styles.webinarCardAuthorName}>Author: {author.full_name}</div>
        </div>
      </div>
    </div>
  );
};
